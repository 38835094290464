/* prefixed by https://autoprefixer.github.io (PostCSS: v7.0.26, autoprefixer: v9.7.3) */

@import url("https://fonts.googleapis.com/css?family=Lato:300,700&display=swap");

$perspective: 1000px;

@mixin defineTranslate($int) {
    -webkit-transform: translateZ($int * $perspective);
    transform: translateZ($int * $perspective);
}

body {
    margin: 0;
    overflow: hidden;
    font-family: "Lato", sans-serif;
}

@font-face {
    font-family: "Digit";
    src: url("./assets/fonts/Digit.TTF");
}

.scrollable-content {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 10;

    .to-hide {
        -webkit-transition: 1s all;
        -o-transition: 1s all;
        transition: 1s all;
    }

    h1 {
        position: absolute;
        color: white;
        opacity: 0.7;
        left: 50%;
        top: 40%;
        -webkit-transform: translate3d(-50%, -150%, 0);
        transform: translate3d(-50%, -150%, 0);
        font-size: 5em;
        -webkit-animation: call-to-scroll 2.5s infinite ease-out;
        animation: call-to-scroll 2.5s infinite ease-out;
    }

    @-webkit-keyframes call-to-scroll {
        0% {
            -webkit-transform: translate3d(-50%, -150%, 0);
            transform: translate3d(-50%, -150%, 0);
            opacity: 0;
        }
        50% {
            -webkit-transform: translate3d(-50%, -50%, 0);
            transform: translate3d(-50%, -50%, 0);
            opacity: 1;
        }
        100% {
            -webkit-transform: translate3d(-50%, 50%, 0);
            transform: translate3d(-50%, 50%, 0);
            opacity: 0;
        }
    }

    @keyframes call-to-scroll {
        0% {
            -webkit-transform: translate3d(-50%, -150%, 0);
            transform: translate3d(-50%, -150%, 0);
            opacity: 0;
        }
        50% {
            -webkit-transform: translate3d(-50%, -50%, 0);
            transform: translate3d(-50%, -50%, 0);
            opacity: 1;
        }
        100% {
            -webkit-transform: translate3d(-50%, 50%, 0);
            transform: translate3d(-50%, 50%, 0);
            opacity: 0;
        }
    }
}

.container-perspective {
    width: 100vw;
    height: 100vh;
    position: fixed;
    perspective: $perspective;
    -webkit-perspective: $perspective;
    -webkit-perspective-origin: center;
    perspective-origin: center;

    .container {
        -webkit-transform-origin: center;
        -ms-transform-origin: center;
        transform-origin: center;
        -webkit-transform-style: preserve-3d;
        transform-style: preserve-3d;
        height: 100%;

        div {
            position: fixed;
            top: -2.5vh;
            left: -2.5vw;
            width: 105vw;
            height: 105vh;
            margin: auto;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
        }

        .img-1 {
            @include defineTranslate(0);
        }
        .img-2 {
            @include defineTranslate(-1);
        }
        .img-3 {
            @include defineTranslate(-2);
        }
        .img-4 {
            @include defineTranslate(-3);
        }
        .img-5 {
            @include defineTranslate(-4);
            width: 120vw;
            height: 120vh;
            top: -10vh;
            left: -10vw;
        }
        .img-6 {
            @include defineTranslate(-5);
        }
        .img-7 {
            @include defineTranslate(-6);
        }
        .img-8 {
            @include defineTranslate(-7);
        }
        .img-9 {
            @include defineTranslate(-8);
        }
        .img-10 {
            @include defineTranslate(-9);
        }
    }
}

.score {
    height: 30px;
    width: 500px;
    position: fixed;
    font-weight: bolder;
    bottom: 20px;
    left: 0;
    right: 0;
    margin: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    z-index: 4;
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;

    .score-france {
        color: white;
        margin-left: 20px;
        z-index: 2;
        -webkit-transition: all 0.5s;
        -o-transition: all 0.5s;
        transition: all 0.5s;
        position: relative;
        bottom: 1px;
    }

    .score-croatia {
        color: white;
        margin-right: 20px;
        z-index: 2;
        -webkit-transition: all 0.5s;
        -o-transition: all 0.5s;
        transition: all 0.5s;
        position: relative;
        bottom: 1px;
    }

    .background-france {
        width: 250px;
        -webkit-transition: all 0.5s;
        -o-transition: all 0.5s;
        transition: all 0.5s;
        position: absolute;
        height: 100%;
        left: 0;
        background-color: #110f32;
        z-index: 1;
        border: solid white 1px;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        border-right: none;
        border-radius: 10px 0px 0px 10px;
    }

    .border-radius {
        border-radius: 10px 10px 10px 10px;
    }

    .background-croatia {
        width: 100%;
        position: absolute;
        right: 0;
        -webkit-transition: all 0.5s;
        -o-transition: all 0.5s;
        transition: all 0.5s;
        height: 100%;
        background-color: #e42e3f;
        border: solid white 1px;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        border-radius: 10px;
    }
}

.chrono {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 70px;
    font-size: 3em;
    margin: auto;
    color: white;
    text-align: center;
    font-family: "Digit", "sans-serif";
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;

    .logo-france {
        margin-right: 20px;
    }

    .logo-croatie {
        margin-left: 30px;
    }
    img {
        height: 80px;
    }
}

.timeline {
    width: 6px;
    height: 500px;
    z-index: 9;
    background-color: rgba(255, 255, 255, 0.5);
    position: fixed;
    left: 30px;
    top: 0;
    bottom: 0;
    margin: auto 0;

    .fulfill-timeline {
        -webkit-transition: all 0.5s ease-out;
        -o-transition: all 0.5s ease-out;
        transition: all 0.5s ease-out;
        height: 0px;
        width: 6px;
        background-color: white;
        position: absolute;
        bottom: 0;
    }

    .completed {
        opacity: 1;
    }

    .active-event {
        -webkit-transform: scale(1.2);
        -ms-transform: scale(1.2);
        transform: scale(1.2);
    }

    div[class^="event"] {
        width: 14px;
        height: 14px;
        border-radius: 50%;
        opacity: 1;
        background-color: white;
        -webkit-transition: all 0.5s;
        -o-transition: all 0.5s;
        transition: all 0.5s;
        position: absolute;
        left: -4px;
        cursor: pointer;

        &:hover {
            &:nth-child(even) {
                background-color: #e42e3f;
            }
            &:nth-child(odd) {
                background-color: #110e32;
            }
        }
    }

    .event-1 {
        bottom: -2px;
    }
    .event-2 {
        bottom: 99px;
    }
    .event-3 {
        bottom: 154px;
    }
    .event-4 {
        bottom: 190px;
    }
    .event-5 {
        bottom: 209px;
    }
    .event-6 {
        bottom: 250px;
    }
    .event-7 {
        bottom: 324px;
    }
    .event-8 {
        bottom: 357px;
    }
    .event-9 {
        bottom: 379px;
    }
    .event-10 {
        bottom: 500px;
    }
}

.home {
    width: 100vw;
    height: 100vw;
    z-index: 10;
    position: fixed;
    background-color: #100e32;
    -webkit-transition: all 2s;
    -o-transition: all 2s;
    transition: all 2s;
    -webkit-transform-origin: center;
    -ms-transform-origin: center;
    transform-origin: center;

    video {
        position: fixed;
        right: 0;
        bottom: 0;
        min-width: 100%;
        min-height: 100%;
        width: auto;
        height: auto;
        -webkit-transition: all 2s;
        -o-transition: all 2s;
        transition: all 2s;
    }

    #start {
        text-align: center;
        position: fixed;
        width: 200px;
        height: 60px;
        background-color: transparent;
        outline: initial;
        border: none;
        color: white;
        font-weight: bolder;
        font-size: 3em;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        font-family: "Lato", "sans-serif";
        -webkit-transition: all 1s;
        -o-transition: all 1s;
        transition: all 1s;
        opacity: 0;
    }
}

.charts {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: fixed;
    width: 100%;
    height: 100vh;
    z-index: 9;

    .chart-2,
    .chart-11 {
        .text {
            margin-top: 20px;
            text-transform: uppercase;
            text-align: center;
            font-weight: bolder;
        }

        .bars {
            height: 400px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: end;
            -ms-flex-align: end;
            align-items: flex-end;
        }

        .bar {
            display: inline-block;
            &:first-of-type {
                margin-right: 100px;
            }

            span {
                position: relative;
                top: 10px;
                font-weight: bolder;
            }
        }
    }

    .chart-2 {
        -webkit-transition: all 0.5s;
        -o-transition: all 0.5s;
        transition: all 0.5s;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        opacity: 0;
        color: white;
        position: fixed;
        bottom: 200px;

        .bar-france,
        .bar-croatia {
            width: 50px;
            height: 0px;
            background-color: white;
            text-align: center;
            color: black;
            -webkit-transition: all 0.5s;
            -o-transition: all 0.5s;
            transition: all 0.5s;

            span {
                font-size: 1.6em;
            }
        }

        .bar-croatia {
            opacity: 0.7;
        }
    }

    .circle {
        position: fixed;
        width: 130px;
        height: 130px;
        border-radius: 50%;
        border: dashed 2px white;
        -webkit-animation: rotate 10s infinite linear;
        animation: rotate 10s infinite linear;
    }

    @-webkit-keyframes rotate {
        to {
            -webkit-transform: rotate(0);
            transform: rotate(0);
        }
        from {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }

    @keyframes rotate {
        to {
            -webkit-transform: rotate(0);
            transform: rotate(0);
        }
        from {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }

    .chart-3 {
        position: fixed;
        right: -300px;
        bottom: 150px;
        width: 200px;
        color: white;
        -webkit-transition: all 0.5s;
        -o-transition: all 0.5s;
        transition: all 0.5s;
        opacity: 0;

        .circle {
            width: 150px;
            height: 150px;
            top: 12vh;
            right: 13vw;
        }

        .title {
            display: block;
            font-size: 7em;
            font-weight: bolder;
            text-align: center;
        }

        p {
            margin: 0;
            text-indent: 20px;

            .bold {
                font-weight: bolder;
            }
        }
    }

    .chart-4,
    .chart-7 {
        .stats {
            background-color: #e42e3f;
            width: 100%;
            height: 20px;

            .france {
                height: 20px;
                background-color: #110f32;
                width: 0px;
                -webkit-transition: all 0.5s;
                -o-transition: all 0.5s;
                transition: all 0.5s;
            }
        }
        .content {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: justify;
            -ms-flex-pack: justify;
            justify-content: space-between;
            margin: 10px 0;

            span {
                text-transform: uppercase;
                &:first-child {
                    font-weight: bolder;
                }
                &:last-child {
                    font-weight: bolder;
                }
            }

            .france-logo {
                background-image: url("./assets/img/logo-france.png");
                background-size: contain;
                background-repeat: no-repeat;
                background-position: left;
                width: 50px;
                height: 50px;
            }

            .croatie-logo {
                background-image: url("./assets/img/logo-croatie.png");
                background-size: contain;
                background-repeat: no-repeat;
                background-position: right;
                width: 50px;
                height: 50px;
            }
        }
    }

    .chart-4 {
        position: fixed;
        top: 30px;
        right: 30px;
        width: 300px;
        color: white;
        opacity: 0;
        -webkit-transition: all 0.5s;
        -o-transition: all 0.5s;
        transition: all 0.5s;
    }

    .chart-5 {
        position: fixed;
        left: -350px;
        opacity: 0;
        -webkit-transition: all 0.5s;
        -o-transition: all 0.5s;
        transition: all 0.5s;

        .data-var {
            background-image: url("./assets/img/var-data.svg");
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            width: 350px;
            height: 350px;
        }
    }

    .chart-6,
    .chart-9 {
        position: fixed;
        right: 0;
        -webkit-transition: all 0.5s;
        -o-transition: all 0.5s;
        transition: all 0.5s;
        opacity: 0;

        .ball {
            background-image: url("./assets/img/ball.png");
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            margin-right: 10px;
            width: 40px;
            height: 40px;
        }

        .best-scorer {
            margin-right: 10px;
        }

        .container {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: end;
            -ms-flex-pack: end;
            justify-content: flex-end;
            margin: 10px 0;
            color: white;

            .scored {
                height: 30px;
                color: black;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                padding-left: 10px;
                margin-left: 10px;
                font-weight: bolder;
                -webkit-transition: all 0.5s;
                -o-transition: all 0.5s;
                transition: all 0.5s;
            }

            .griezmann {
                font-weight: bolder;
            }
        }
    }
    .chart-6 {
        .circle {
            top: 5%;
            right: 32vw;
            width: 220px;
            height: 220px;
        }
        .score-1 {
            width: 0px;
            background-color: rgba(255, 255, 255, 1);
        }

        .score-2 {
            width: 0px;
            background-color: rgba(255, 255, 255, 0.8);
        }
        .score-3 {
            width: 0px;
            background-color: rgba(255, 255, 255, 0.6);
        }
        .score-4 {
            width: 0px;
            background-color: rgba(255, 255, 255, 0.4);
        }
    }
    .chart-7 {
        position: fixed;
        bottom: 250px;
        width: 400px;
        color: white;
        -webkit-transition: all 0.5s;
        -o-transition: all 0.5s;
        transition: all 0.5s;
        opacity: 0;

        .stats {
            -webkit-transition: all 0.5s;
            -o-transition: all 0.5s;
            transition: all 0.5s;
            width: 0;

            .france {
                -webkit-transition: all 0.5s;
                -o-transition: all 0.5s;
                transition: all 0.5s;
                width: 0;
                background-color: #fff;
            }
        }

        .stats-1 {
            -webkit-transition-delay: 0s;
            -o-transition-delay: 0s;
            transition-delay: 0s;

            .france {
                -webkit-transition-delay: 0.2s;
                -o-transition-delay: 0.2s;
                transition-delay: 0.2s;
            }
        }
        .stats-2 {
            -webkit-transition-delay: 0.2s;
            -o-transition-delay: 0.2s;
            transition-delay: 0.2s;
            .france {
                -webkit-transition-delay: 0.4s;
                -o-transition-delay: 0.4s;
                transition-delay: 0.4s;
            }
        }
        .stats-3 {
            -webkit-transition-delay: 0.4s;
            -o-transition-delay: 0.4s;
            transition-delay: 0.4s;
            .france {
                -webkit-transition-delay: 0.6s;
                -o-transition-delay: 0.6s;
                transition-delay: 0.6s;
            }
        }
        .stats-4 {
            -webkit-transition-delay: 0.6s;
            -o-transition-delay: 0.6s;
            transition-delay: 0.6s;
            .france {
                -webkit-transition-delay: 0.8s;
                -o-transition-delay: 0.8s;
                transition-delay: 0.8s;
            }
        }
        .stats-5 {
            -webkit-transition-delay: 0.8s;
            -o-transition-delay: 0.8s;
            transition-delay: 0.8s;
            .france {
                -webkit-transition-delay: 1s;
                -o-transition-delay: 1s;
                transition-delay: 1s;
            }
        }
    }

    .chart-8 {
        position: fixed;
        color: white;
        -webkit-transition: all 0.5s;
        -o-transition: all 0.5s;
        transition: all 0.5s;
        right: -300px;

        .pogba-goals {
            font-weight: bolder;
            font-size: 1.2em;
        }

        span {
            font-size: 9em;
            font-weight: bolder;
        }
        p {
            width: 300px;
        }
    }

    .chart-9 {
        .youngest {
            font-weight: bolder;
        }
        .player-1 {
            width: 0px;
            background-color: rgba(255, 255, 255, 0.6);
        }

        .player-2 {
            width: 0px;
            background-color: rgba(255, 255, 255, 0.8);
        }
        .player-3 {
            width: 0px;
            background-color: rgba(255, 255, 255, 1);
        }
        p {
            color: white;
            width: 300px;
            float: right;
        }
    }
    .chart-10 {
        .chart-10-pie {
            position: fixed;
            top: 20px;
            right: 25px;
            opacity: 0;
            -webkit-transition: all 0.5s;
            -o-transition: all 0.5s;
            transition: all 0.5s;
            p {
                color: white;
                width: 250px;
            }
            .arrest {
                background-image: url("./assets/img/data-lloris.svg");
                background-repeat: no-repeat;
                background-size: cover;
                width: 200px;
                height: 200px;
                -webkit-transform: rotate(0deg);
                -ms-transform: rotate(0deg);
                transform: rotate(0deg);
                -webkit-animation: lloris-data 10s infinite alternate;
                animation: lloris-data 10s infinite alternate;
                margin: auto;
            }

            @-webkit-keyframes lloris-data {
                from {
                    -webkit-transform: rotate(60deg);
                    transform: rotate(60deg);
                }

                to {
                    -webkit-transform: rotate(-30deg);
                    transform: rotate(-30deg);
                }
            }

            @keyframes lloris-data {
                from {
                    -webkit-transform: rotate(60deg);
                    transform: rotate(60deg);
                }

                to {
                    -webkit-transform: rotate(-30deg);
                    transform: rotate(-30deg);
                }
            }
        }

        .chart-10-bars {
            position: fixed;
            right: 0;
            bottom: 150px;
            opacity: 0;
            -webkit-transition: all 0.5s;
            -o-transition: all 0.5s;
            transition: all 0.5s;

            .best-selected {
                margin-right: 10px;
                color: white;
                font-weight: bolder;
                text-align: right;
            }
            .container {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                -webkit-box-pack: end;
                -ms-flex-pack: end;
                justify-content: flex-end;
                margin: 10px 0;
                color: white;

                .selection {
                    height: 30px;
                    color: black;
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    align-items: center;
                    padding-left: 10px;
                    margin-left: 10px;
                    font-weight: bolder;
                    -webkit-transition: all 0.5s;
                    -o-transition: all 0.5s;
                    transition: all 0.5s;
                }

                .selected-1 {
                    background-color: rgba(255, 255, 255, 0.16);
                }
                .selected-2 {
                    background-color: rgba(255, 255, 255, 0.32);
                }
                .selected-3 {
                    background-color: rgba(255, 255, 255, 0.48);
                }
                .selected-4 {
                    background-color: rgba(255, 255, 255, 0.64);
                }
                .selected-5 {
                    background-color: rgba(255, 255, 255, 0.8);
                }
                .selected-6 {
                    background-color: rgba(255, 255, 255, 1);
                }

                .lloris {
                    font-weight: bolder;
                }
            }
        }
    }
    .chart-11 {
        position: fixed;
        bottom: 200px;
        opacity: 0;
        -webkit-transition: all 0.5s;
        -o-transition: all 0.5s;
        transition: all 0.5s;

        .bars {
            height: 300px;
        }

        .cup {
            background-image: url("./assets/img/cup.png");
            background-repeat: no-repeat;
            background-size: contain;
            width: 50px;
            height: 100px;
            margin: auto;
        }

        .bar {
            -webkit-transition: all 0.5s;
            -o-transition: all 0.5s;
            transition: all 0.5s;
            width: 50px;
            height: 0px;
            background-color: white;
            text-align: center;
            color: black;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            -webkit-box-pack: justify;
            -ms-flex-pack: justify;
            justify-content: space-between;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;

            .victory-1 {
                font-size: 1.8em;
            }

            .victory-2 {
                font-size: 1.6em;
            }

            .victory-3 {
                font-size: 1.4em;
            }

            .victory-4 {
                font-size: 1em;
            }
            .flag {
                top: 0px;
                left: 0px;
                display: block;
                text-align: center;
                font-size: 1.5em;

                .argentina {
                    position: relative;
                    top: 20px;
                }

                .england {
                    left: 3px;
                    top: 5px;
                }

                .spain {
                    top: 0;
                    left: 3px;
                }
            }
        }

        .winner-4 {
            .flag {
                font-size: 1em;
            }
        }

        .text {
            color: white;
        }
        .winner-1,
        .winner-2,
        .winner-3 {
            margin-right: 100px;
        }
    }
}

.black {
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
    opacity: 0;
    background-color: black;
}

.fade-in {
    opacity: 1 !important;
}

.chart-3-trans {
    opacity: 1 !important;
    right: 50px !important;
}

.chart-5-trans {
    opacity: 1 !important;
    left: 220px !important;
}

.chart-8-trans {
    right: 10px !important;
    opacity: 1 !important;
}

.chart-10-trans {
    right: 20px !important;
    opacity: 1 !important;
}

.fade-out {
    opacity: 0 !important;
}

.hide {
    display: none !important;
}

.black-fade {
    opacity: 0.4;
}

.end-screen {
    width: 100vw;
    height: 100vh;
    background-color: #110f32;
    position: fixed;
    top: 0;
    left: 0;
    opacity: 0;
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;

    h1,
    h2,
    h3 {
        text-align: center;
        margin: 0;
        color: white;
    }

    h2 {
        font-weight: lighter;
    }

    h3 {
        margin-top: 50px;
        margin-bottom: 20px;
    }

    .faces {
        background-image: url("./assets/img/faces.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        width: 300px;
        height: 100px;
        margin: auto;
    }

    .logo-gobelins {
        position: fixed;
        background-image: url("./assets/img/logo-gobelins.png");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        width: 150px;
        height: 150px;
        bottom: 0px;
        left: 0;
        right: 0;
        margin: auto;
    }
}

.loader {
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    right: 0;
    z-index: 30;
    background-color: #110f32;
    color: white;
    -webkit-transition: 3s all;
    -o-transition: 3s all;
    transition: 3s all;

    .user-choice {
        font-size: 1.2em;
        text-align: center;
        margin-top: 25vh;

        span {
            font-weight: bolder;
        }
    }

    .user-device {
        width: 300px;
        margin: auto;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        margin-top: 10vh;

        .separator {
            width: 1px;
            height: 100px;
            background-color: #fff;
        }

        .mouse {
            width: 100px;
            height: 100px;
            cursor: pointer;
            -webkit-transition: all 0.5s;
            -o-transition: all 0.5s;
            transition: all 0.5s;
            background-image: url("./assets/img/mouse.png");
            background-size: contain;
            background-position: center;
            &:hover {
                -webkit-transform: scale(1.1);
                -ms-transform: scale(1.1);
                transform: scale(1.1);
            }
        }

        .pad {
            width: 100px;
            height: 100px;
            background-image: url("./assets/img/trackpad.png");
            background-size: contain;
            background-position: center;
            cursor: pointer;
            -webkit-transition: all 0.5s;
            -o-transition: all 0.5s;
            transition: all 0.5s;

            &:hover {
                -webkit-transform: scale(1.1);
                -ms-transform: scale(1.1);
                transform: scale(1.1);
            }
        }
    }

    .footer {
        position: fixed;
        bottom: 10vh;
        left: 0;
        right: 0;
        -webkit-transition: all 0.5s;
        -o-transition: all 0.5s;
        transition: all 0.5s;
        margin: auto;

        .loading-line {
            width: 0;
            height: 3px;
            background-color: #fff;
            position: relative;
            top: 30px;
            -webkit-transition: all 1s;
            -o-transition: all 1s;
            transition: all 1s;
        }

        .loading-text {
            text-align: center;
        }
    }
}

.user-anim {
    -webkit-transition: all 2s;
    -o-transition: all 2s;
    transition: all 2s;
    opacity: 0;
}

.remove-pointer-events {
    pointer-events: none;
}
